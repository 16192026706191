import { px2rem } from './px2rem'
import { styled, ThemeProvider } from './vue-sx'
import getStyledComponentName from './getStyledComponentName'

export default {
  px2rem,
  styled,
  ThemeProvider,
  getStyledComponentName,
}
