import { useSignupStore } from '@/stores/signup'
import { useTokenStore } from '@/stores/token'

export function useSignupCode() {
  return {
    async beforeEnter(to, from, next) {
      const { signup } = useSignupStore()
      const token = useTokenStore()
      if (!token.hasValidTeacherToken()) {
        if (to.meta.requiresCode && !signup.code) {
          next('/signup')
        } else {
          next()
        }
      } else {
        next('/')
      }
    },
  }
}
