import { logException } from './logger'
import i18n from '@/i18n'

const t = i18n.global.t

export class ApplicationError extends Error {
  translationKey
  constructor(message: string, translationKey: string) {
    super(message)
    this.name = 'UserFacing'
    this.translationKey = translationKey
  }

  getLocalizedMessage() {
    return t(this.translationKey)
  }
}

export function handleApplicationError(
  err: any,
  fallbackKey = 'generic.error.server'
): string {
  if (err instanceof ApplicationError) {
    return err.getLocalizedMessage()
  }
  logException(err)
  return t(fallbackKey)
}
