import { convertV2toV1limits } from './limits.js';
import { updateRowColors } from './matrixColors.js';
import { addPoints, sum } from './points.js';
import { calculateExamMomentLimits } from './gradeThresholds.js';
export function abilityMatrix(criterias, abilities, limits, connectedAbilities, skipBlankRows) {
    const map = criterias.reduce((map, criteria) => {
        var _a, _b;
        const abilityKey = criteria.abilityKey;
        map.set(abilityKey, {
            given: {
                points: addPoints(((_a = map.get(abilityKey)) === null || _a === void 0 ? void 0 : _a.given.points) || [0, 0, 0], criteria.givenPoints),
            },
            available: {
                points: addPoints(((_b = map.get(abilityKey)) === null || _b === void 0 ? void 0 : _b.available.points) || [0, 0, 0], criteria.availablePoints),
            },
            key: abilityKey,
            name: criteria.ability,
            sort: 0, // FIXME check this
        });
        return map;
    }, new Map());
    if (Object.keys(connectedAbilities).length === 0) {
        return addColorField(sortAndAddTotals(map));
    }
    const connectedMap = applyConnectedAbilities(map, abilities, connectedAbilities);
    const withColors = applyLimitColors(sortAndAddTotals(connectedMap), limits);
    if (skipBlankRows) {
        return withColors.filter((row) => row.available.points[0] !== 0);
    }
    return withColors;
}
function applyConnectedAbilities(map, abilities, connectedAbilities) {
    const pointMap = new Map();
    Object.keys(connectedAbilities).forEach((id) => {
        var _a;
        const index = connectedAbilities[id];
        const abilityName = ((_a = abilities.find((a) => a.id === id)) === null || _a === void 0 ? void 0 : _a.name) || '';
        const currentObject = pointMap.get(index);
        if (!currentObject) {
            pointMap.set(index, {
                given: {
                    points: [0, 0, 0],
                },
                available: {
                    points: [0, 0, 0],
                },
                name: abilityName,
                sort: index,
            });
        }
        else {
            pointMap.set(index, {
                given: {
                    points: [0, 0, 0],
                },
                available: {
                    points: [0, 0, 0],
                },
                name: `${currentObject.name} & ${abilityName}`,
                sort: index,
            });
        }
    });
    const abilitiesWithPoints = Array.from(map.values()).reduce((acc, obj) => {
        var _a;
        const index = connectedAbilities[(_a = obj.key) !== null && _a !== void 0 ? _a : ''];
        const currentObject = acc.get(index);
        if (currentObject) {
            currentObject.given.points = addPoints(currentObject.given.points, obj.given.points);
            currentObject.available.points = addPoints(currentObject.available.points, obj.available.points);
        }
        return acc;
    }, pointMap);
    return abilitiesWithPoints;
}
function sortAndAddTotals(map) {
    const sorted = Array.from(map.values())
        .sort((a, b) => a.sort - b.sort)
        .map((row) => {
        // remove sort key
        const sortedRow = {
            name: row.name,
            given: row.given,
            available: row.available,
        };
        return sortedRow;
    });
    const givenMatrixTotal = sorted.reduce((acc, subchapterRow) => {
        return addPoints(acc, subchapterRow.given.points);
    }, [0, 0, 0]);
    const availableMatrixTotal = sorted.reduce((acc, subchapterRow) => {
        return addPoints(acc, subchapterRow.available.points);
    }, [0, 0, 0]);
    sorted.push({
        given: {
            points: givenMatrixTotal,
        },
        available: {
            points: availableMatrixTotal,
        },
        name: '',
    });
    const givenTotalColumn = sorted.reduce((acc, sortedRow) => {
        const points = sum(sortedRow.given.points);
        return [...acc, points];
    }, []);
    const availableTotalColumn = sorted.reduce((acc, sortedRow) => {
        const availablePoints = sum(sortedRow.available.points);
        return [...acc, availablePoints];
    }, []);
    return sorted.map((subchapterRow, index) => {
        const row = {
            name: subchapterRow.name,
            given: {
                points: subchapterRow.given.points,
                rowTotal: givenTotalColumn[index],
            },
            available: {
                points: subchapterRow.available.points,
                rowTotal: availableTotalColumn[index],
            },
        };
        return row;
    });
}
export function subchapterMatrixWithDefaultLimits(criterias, sorting) {
    const limits = calculateExamMomentLimits(criterias, sorting);
    return subchapterMatrix(criterias, limits, sorting);
}
export function subchapterMatrix(criteria, limits, sorting) {
    const map = criteria.reduce((map, obj) => {
        var _a, _b, _c;
        map.set(obj.subchapterId, {
            sort: ((_a = sorting.find((s) => s.subchapterId === obj.subchapterId)) === null || _a === void 0 ? void 0 : _a.globalSort) || 100000000,
            given: {
                points: addPoints(((_b = map.get(obj.subchapterId)) === null || _b === void 0 ? void 0 : _b.given.points) || [0, 0, 0], obj.givenPoints),
            },
            available: {
                points: addPoints(((_c = map.get(obj.subchapterId)) === null || _c === void 0 ? void 0 : _c.available.points) || [0, 0, 0], obj.availablePoints),
            },
            name: obj.subchapterName,
        });
        return map;
    }, new Map());
    if (limits.length === 0) {
        return addColorField(sortAndAddTotals(map));
    }
    else {
        return applyLimitColors(sortAndAddTotals(map), limits);
    }
}
function addColorField(matrix) {
    return matrix.map((row) => {
        row.colors = ['transparent', 'transparent', 'transparent'];
        return row;
    });
}
function applyLimitColors(matrix, limits) {
    const otg = { e: 1, c: 1, a: 1 }; // otg = ok to green
    const limitsV1 = convertV2toV1limits(limits);
    return matrix.map((row, index) => {
        const colors = { e: 'white', c: 'white', a: 'white' };
        const points = {
            e: row.given.points[0],
            c: row.given.points[1],
            a: row.given.points[2],
        };
        updateRowColors(colors, points, index === matrix.length - 1, limitsV1[index], otg);
        row.colors = [colors.e, colors.c, colors.a];
        return row;
    });
}
