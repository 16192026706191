export const updateFieldByPath = (
  inputObj: Record<string, any>,
  field: string[],
  value: any
) => {
  if (!field || field.length === 0 || !Array.isArray(field)) {
    throw new Error('Bad field specification when updating settings')
  }

  // Access the last element directly without modifying the array
  const leaf = field[field.length - 1]

  // Proceed with the rest of your logic
  let obj = inputObj
  for (let i = 0; i < field.length - 1; i++) {
    if (!(field[i] in obj)) {
      throw new Error(`Exam settings field not found: ${field[i]}`)
    }
    obj = obj[field[i]]
  }

  // Update the value
  obj[leaf] = value
}
