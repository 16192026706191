<!-- License note: file modified by Atanas F. Nikolov -->

<template>
  <slot />
</template>

<script setup lang="ts">
import { provide } from 'vue'
import { themeKey } from './Theme'

type Props = {
  theme: object
}

const props = defineProps<Props>()

provide(themeKey, props.theme)
</script>
