import http, { throwOnAxiosError } from './httpKM'

export const KM = {
  async getQuestionsWithPDOService(
    courseId,
    momentIds,
    schoolid,
    teacherid,
    courseType,
    searchFilter
  ) {
    if (!searchFilter) {
      searchFilter = {
        ownUppgifter: 1, // 1:publika, 2:egna
        autoGrade: 2,
        calculator: 0,
        levels: [false, false, false],
        examType: -1,
        examTypeOptions: [
          { value: -1, label: 'Alla uppgifter' },
          { value: 1, label: 'Endast svar' },
          { value: 0, label: 'Redovisa lösning' },
        ],
      }
      //  matrisblock=2 // spelar ingen roll
      //  matrisblock=1 //ta bort matrisblockerade.   Ger mkt färre  antal frågor.
    }
    const apiUrlAxios = '/service/pdoservice.php'

    const searchparameters = {
      skillfilter: [
        [1, 1, 1],
        [1, 1, 1],
        [1, 1, 1],
        [1, 1, 1],
        [1, 1, 1],
        [1, 1, 1],
      ],
      specialtask: '[0,1,2,3,4]',
      autocorr: searchFilter.autoGrade,
      svarsalt: [],
      matrixblock: '2',
      scope: `[${searchFilter.ownUppgifter}]`,
    }
    searchparameters.skillfilter = [
      [
        searchFilter.levels[0] ? 1 : 0,
        searchFilter.levels[1] ? 1 : 0,
        searchFilter.levels[2] ? 1 : 0,
      ],
      [
        searchFilter.levels[0] ? 1 : 0,
        searchFilter.levels[1] ? 1 : 0,
        searchFilter.levels[2] ? 1 : 0,
      ],
      [
        searchFilter.levels[0] ? 1 : 0,
        searchFilter.levels[1] ? 1 : 0,
        searchFilter.levels[2] ? 1 : 0,
      ],
      [
        searchFilter.levels[0] ? 1 : 0,
        searchFilter.levels[1] ? 1 : 0,
        searchFilter.levels[2] ? 1 : 0,
      ],
      [
        searchFilter.levels[0] ? 1 : 0,
        searchFilter.levels[1] ? 1 : 0,
        searchFilter.levels[2] ? 1 : 0,
      ],
      [
        searchFilter.levels[0] ? 1 : 0,
        searchFilter.levels[1] ? 1 : 0,
        searchFilter.levels[2] ? 1 : 0,
      ],
    ]
    if (searchFilter.examType !== -1) {
      searchparameters.specialtask = '[0]' // `[${searchFilter.ownUppgifter}]`
      searchparameters.svarsalt.push({
        kortsvar: Number(searchFilter.examType) === 1 ? 1 : 0,
      })
    }

    if (Number(searchFilter.calculator) === 2)
      searchparameters.svarsalt.push({ digitalaverktyg: 1 })
    if (Number(searchFilter.calculator) === 1)
      searchparameters.svarsalt.push({ digitalaverktyg: 0 })

    const params = new URLSearchParams()
    params.append('searchumoments', momentIds.split(',').join('|'))
    params.append('course', courseId)
    params.append('startlimit', 0) // Detta är för varje moment
    params.append('stoplimit', 9) // Detta är för varje moment
    params.append('searchparameters', JSON.stringify(searchparameters))
    params.append('t', teacherid)
    params.append('schoolid', schoolid)
    params.append('c', Number(teacherid) * 128 + 17)
    params.append('coursetype', courseType || 'u')
    try {
      return http.post(apiUrlAxios, params)
    } catch (error) {
      throwOnAxiosError(error, 'Failed to get questions')
    }
  },
  async getBook(file) {
    const apiUrlAxios =
      (import.meta.env.VITE_KM_BOOK_URL || import.meta.env.VITE_KM_URL) +
      '/js/books/' +
      file
    try {
      return http.get(apiUrlAxios)
    } catch (error) {
      throwOnAxiosError(error, 'Failed to get book')
    }
  },
  async saveTest(paramObj) {
    const apiUrlAxios = '/service/generics.php'
    const params = new URLSearchParams()
    for (const key in paramObj) {
      const element = paramObj[key]
      params.append(key, element)
    }

    try {
      return http.post(apiUrlAxios, params)
    } catch (error) {
      throwOnAxiosError(error, 'Failed to save test')
    }
  },
  async sendEmailFeedback({ version, message, teacherid }) {
    const apiUrlAxios = '/service/generics.php'
    const axiosConfig = {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    }
    const params = new URLSearchParams()

    params.append('mail_feedback', message)
    params.append('t', teacherid)
    params.append('version', version)

    try {
      return http.post(apiUrlAxios, params, axiosConfig)
    } catch (error) {
      throwOnAxiosError(error, 'Failed to send feedback')
    }
  },
  async getLiteratureInfoFromIds(literatureIds) {
    const apiUrlAxios = '/service/pdoservice.php'
    const params = new URLSearchParams()
    params.append('datafromlitids', literatureIds.join(','))
    try {
      return http.post(apiUrlAxios, params)
    } catch (error) {
      throwOnAxiosError(error, 'Failed to get literature info')
    }
  },
}
