import http from './httpApi'

export const APIv2 = {
  async getClass(id) {
    const res = await http.get(`/v2/class/${id}`)
    return res.data
  },
  async getRedirectUrl({ teacherId, bookKey, courseId, examId }) {
    const res = await http.post(`/v2/teachers/${teacherId}/old-exam-editor`, {
      examId,
      courseId,
      bookKey,
    })
    return res.data.url
  },

  async checkQuestions(courseId, questions) {
    const res = await http.post('/v2/tests/check-questions', {
      courseId,
      questions,
    })
    return res.data
  },
}
