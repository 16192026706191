export function generateDefaultLimitarr(
  subjectId,
  connectinput,
  connpointsperformaga
) {
  const thislimitarr = {
    connectformagor: [],
    limits: [],
  }
  thislimitarr.connectformagor = connectinput
  let limitFactors = []
  if ([8, 9, 10, 27, 28, 64, 77, 89].includes(subjectId)) {
    // Svenska
    limitFactors = [0.45, 0.5, 0.7, 0.75, 0.85, 0.9]
  } else if ([13, 31, 70, 11, 29, 79, 12, 30, 71, 80].includes(subjectId)) {
    // engelska & moderna språk
    limitFactors = [0.34, 0.4, 0.65, 0.7, 0.89, 0.9]
  }
  for (let f = 0; f < connpointsperformaga[0].length; f++) {
    if (limitFactors.length > 0) {
      // Color with -1 so tests with e-points can give C and A
      const totalPoints =
        connpointsperformaga[0][f] +
        connpointsperformaga[1][f] +
        connpointsperformaga[2][f]
      thislimitarr.limits.push([
        [
          [Math.round(totalPoints * limitFactors[0]), 0],
          [Math.round(totalPoints * limitFactors[1]), 0],
          connpointsperformaga[0][f],
        ],
        [
          [Math.round(totalPoints * limitFactors[2]), -1],
          [Math.round(totalPoints * limitFactors[3]), -1],
          connpointsperformaga[1][f],
        ],
        [
          [Math.round(totalPoints * limitFactors[4]), -1],
          [Math.round(totalPoints * limitFactors[5]), -1],
          connpointsperformaga[2][f],
        ],
      ])
    } else {
      // This is the default limitarr for tests with e,c,a-points
      thislimitarr.limits.push([
        [
          [Math.ceil(connpointsperformaga[0][f] * 0.5), 0],
          [Math.ceil(connpointsperformaga[0][f] * 0.72), 0],
          connpointsperformaga[0][f],
        ],
        [
          [
            Math.ceil(connpointsperformaga[0][f] * 0.75),
            Math.ceil(connpointsperformaga[1][f] * 0.33),
          ],
          [
            Math.ceil(
              (connpointsperformaga[0][f] + connpointsperformaga[1][f]) * 0.72
            ),
            Math.ceil(connpointsperformaga[1][f] * 0.59),
          ],
          connpointsperformaga[1][f],
        ],
        [
          [
            Math.ceil(
              (connpointsperformaga[0][f] + connpointsperformaga[1][f]) * 0.75
            ),
            Math.ceil(connpointsperformaga[2][f] * 0.3),
          ],
          [
            Math.ceil(
              (connpointsperformaga[0][f] + connpointsperformaga[1][f]) * 0.79 +
                connpointsperformaga[2][f] * 0.56
            ),
            Math.ceil(connpointsperformaga[2][f] * 0.56),
          ],
          connpointsperformaga[2][f],
        ],
      ])
    }
  }
  return thislimitarr
}
