import { isPxUnits } from './regExp'

const rootFontSizeBase = 14

export const px2rem = (pxValue) => {
  if (typeof pxValue === 'string' && isPxUnits.test(pxValue)) {
    pxValue.replace('px', '')
  }
  return parseFloat(pxValue / rootFontSizeBase).toFixed(4) + 'rem'
}
