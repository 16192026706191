import { ToastSeverity } from '@primevue/core/api'
import { TOAST_LIFETIME } from '@/constants'

export class ToastService {
  toast
  // constructor
  constructor(toast: any) {
    this.toast = toast
  }

  success(message = 'Success'): void {
    this.toast.add({
      severity: ToastSeverity.SUCCESS,
      summary: message,
      life: TOAST_LIFETIME,
    })
  }

  error(message = 'Error'): void {
    this.toast.add({
      severity: ToastSeverity.ERROR,
      summary: message,
      life: TOAST_LIFETIME,
    })
  }

  warning(message = 'Warning'): void {
    this.toast.add({
      severity: ToastSeverity.WARN,
      summary: message,
      life: TOAST_LIFETIME,
    })
  }
}
