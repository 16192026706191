import { getCurrentInstance } from 'vue'

export default function getStyledComponentName(defaultName) {
  let parentType = getCurrentInstance()?.parent?.parent?.parent?.type
  if (parentType === 'HtmlElement') {
    parentType = parentType.parent?.type
  }
  let componentName = `${parentType?.name || parentType?.__name}-${defaultName}`

  componentName = componentName.replace(/HtmlElement-/g, '')

  return componentName
}
