import { http, httpShared, throwOnAxiosError } from './http'

export async function questionSearchByNodeId(nodeIds, params) {
  let queryParams = nodeIds.map((id) => `nodeIds[]=${id}`).join('&')
  if (params.sortBy) {
    const [field, direction] = params.sortBy.split('-')
    queryParams += `&orderBy[0][field]=${field}&orderBy[0][direction]=${direction}`
  }
  if (typeof params.autocorrect === 'boolean') {
    queryParams +=
      '&tags[]=' + (params.autocorrect ? 'autocorrect' : 'no:autocorrect')
  }
  if (typeof params.calculator === 'boolean') {
    queryParams +=
      '&tags[]=' + (params.calculator ? 'calculator' : 'no:calculator')
  }
  if (params.abilities || params.pointTypes) {
    const abilities = params.abilities || ['']
    const difficulties = params.pointTypes || ['']
    let pair = 0
    abilities.forEach((a) => {
      difficulties.forEach((d) => {
        const aQuery = a !== '' ? `&abilities[${pair}][ability]=${a}` : ''
        const dQuery = d !== '' ? `&abilities[${pair}][difficulty]=${d}` : ''
        queryParams += aQuery + dQuery
        pair++
      })
    })
  }
  if (params.visibility) {
    queryParams += `&visibility=${params.visibility}`
  }
  if (params.query) {
    queryParams += `&query=${params.query}`
  }
  if (params.type) {
    queryParams += `&type=${params.type}`
  }
  if (params.literature) {
    queryParams += `&attachment=${params.literature}`
  }
  if (typeof params.page === 'number' && params.page >= 0) {
    queryParams += `&page=${params.page}`
  }
  if (typeof params.pageSize === 'number' && params.pageSize > 0) {
    queryParams += `&pageSize=${params.pageSize}`
  }
  if (params.practiceQuestion) {
    queryParams += `&practiceQuestion=${params.practiceQuestion}`
  }
  try {
    const res = await http.get(`/v1/search?${queryParams}`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to search questions')
  }
}

// Used for listing questions during editing of books
export async function questionFindBySubchapterId(subchapterId) {
  try {
    const res = await http.get(`/v1/subchapter/${subchapterId}/questions`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to list questions')
  }
}

export async function questionGetById(questionId, questionVersionId) {
  let url
  if (!questionId) {
    url = `/v1/question/ByVersionId`
  } else {
    url = `/v1/question/${questionId}`
  }
  if (
    questionVersionId &&
    questionVersionId !== 'latest' &&
    questionVersionId !== 'current'
  ) {
    url += `/${questionVersionId}`
  }
  try {
    const res = await httpShared.get(url)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve question')
  }
}

export async function questionGetByVersionId(questionVersionId) {
  try {
    const res = await httpShared.get(
      `/v1/question/ByVersionId/${questionVersionId}`
    )
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve question by version id')
  }
}

export async function questionReport(versionId, { type, comment }) {
  try {
    await http.post(`/v1/question/${versionId}/report`, {
      type,
      comment,
    })
    return true
  } catch (err) {
    throwOnAxiosError(err, 'Failed to report question', true)
  }
}
