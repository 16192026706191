import axios from 'axios'
import { useTokenStore } from '@/stores/token'
import { UserFacingError } from '@/utils/UserFacingError'

axios.defaults.params = {}
const http = axios.create({
  headers: { 'Content-Type': 'application/json' },
  baseURL: import.meta.env.VITE_API_BASE_URL
    ? import.meta.env.VITE_API_BASE_URL + '/api'
    : '/api',
})

const httpShared = axios.create({
  headers: { 'Content-Type': 'application/json' },
  baseURL: import.meta.env.VITE_API_BASE_URL
    ? import.meta.env.VITE_API_BASE_URL + '/shared-api'
    : '/shared-api',
})
const httpStudent = axios.create({
  headers: { 'Content-Type': 'application/json' },
  baseURL: import.meta.env.VITE_API_BASE_URL
    ? import.meta.env.VITE_API_BASE_URL + '/student-api'
    : '/student-api',
})

setupInterceptors(http)
setupInterceptors(httpShared)
setupInterceptors(httpStudent)

function setupInterceptors(http) {
  http.interceptors.request.use(
    (config) => {
      const token = useTokenStore()
      if (token?.hasToken) {
        config.headers.Authorization = 'Bearer ' + token.token
      }
      return config
    },
    (error) => {
      return Promise.reject(
        new UserFacingError(
          error,
          'Network error',
          false,
          'generic.error.network'
        )
      )
    }
  )

  http.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (typeof error.response === 'undefined') {
        return Promise.reject(
          new UserFacingError(
            error,
            'Network error',
            false,
            'generic.error.network'
          )
        )
      }
      if (error.response.status === 401) {
        const message = 'Session expired'

        return Promise.reject(
          new UserFacingError(
            error,
            message,
            false,
            'generic.error.auth',
            error.response.status
          )
        )
      }
      if (error.response.status === 403) {
        const message = 'Access denied'

        return Promise.reject(
          new UserFacingError(
            error,
            message,
            false,
            'generic.error.accessDenied',
            error.response.status
          )
        )
      }
      return Promise.reject(error)
    }
  )
}

function throwOnAxiosError(error, message = 'Something went wrong') {
  if (error instanceof UserFacingError) {
    throw error
  }
  throw new UserFacingError(error, message)
}

export { http, httpShared, httpStudent, throwOnAxiosError }
