import { defineStore } from 'pinia'
import {
  useLocalStorage,
  useSessionStorage,
  type RemovableRef,
} from '@vueuse/core'
import { useUserStore } from './user'
import { getMasqueradeToken } from '@/services/user'
import { validSecondsToValidTo } from '@/utils/validSecondsToValidTo'

type TokenState = {
  teacherToken: RemovableRef<string | null>
  validTo: RemovableRef<string | null>
  studentToken: RemovableRef<string | null>
  studentValidTo: RemovableRef<string | null>
  masqueradeUserId: number
  masqueradeToken: string | null
  masqueradeValidTo: string | null
  mode: RemovableRef<LoginMode | null>
}

export const useTokenStore = defineStore('token', {
  state: () =>
    ({
      teacherToken: useLocalStorage('token', null),
      validTo: useLocalStorage('validTo', null),
      studentToken: useLocalStorage('studentToken', null),
      studentValidTo: useLocalStorage('studentValidTo', null),
      masqueradeUserId: 0,
      masqueradeToken: '',
      masqueradeValidTo: '',
      mode: useSessionStorage('mode', null),
    }) as TokenState,
  getters: {
    token(): string | null {
      if (this.mode === 'student') {
        return this.studentToken
      }
      if (this.mode === 'teacher') {
        return this.teacherToken
      }
      if (this.mode === 'masquerade') {
        return this.masqueradeToken
      }
      return null
    },
    hasToken(): boolean {
      if (this.mode === 'student') {
        return !!this.studentToken
      }
      if (this.mode === 'teacher') {
        return !!this.teacherToken
      }
      if (this.mode === 'masquerade') {
        return !!this.masqueradeToken
      }
      return false
    },
    hasTeacherToken(): boolean {
      return !!this.teacherToken
    },
    teacherMode(): boolean {
      return this.mode === 'teacher' || this.mode === 'masquerade'
    },
  },
  actions: {
    setMode(mode: LoginMode) {
      this.mode = mode
    },
    hasValidStudentToken(): boolean {
      if (this.studentValidTo) {
        const validToDate = new Date(this.studentValidTo)
        if (validToDate < new Date()) {
          return false
        }
      }
      return !!this.studentToken
    },
    hasValidPersonalToken(): boolean {
      if (this.validTo) {
        const validToDate = new Date(this.validTo)
        if (validToDate < new Date()) {
          return false
        }
      }
      return !!this.teacherToken
    },
    hasValidMasqueradeToken(): boolean {
      if (this.masqueradeValidTo) {
        const validToDate = new Date(this.masqueradeValidTo)
        if (validToDate < new Date()) {
          return false
        }
      }
      return !!this.masqueradeToken
    },
    hasValidTeacherToken(): boolean {
      return this.hasValidPersonalToken() || this.hasValidMasqueradeToken()
    },
    hasValidAdminToken(): boolean {
      const userStore = useUserStore()
      return this.hasValidPersonalToken() && userStore.admin
    },
    async ensureMasquerade(userId: number) {
      if (this.masqueradeUserId === userId && this.hasValidMasqueradeToken()) {
        this.mode = 'masquerade'
        return
      }
      const userStore = useUserStore()
      // Switch to teacher mode to request masquerade token with correct permissions
      this.mode = 'teacher'
      try {
        const response = await getMasqueradeToken(userId)
        this.mode = 'masquerade'
        const validTo = validSecondsToValidTo(response.validSeconds)
        this.masqueradeUserId = userId
        this.masqueradeToken = response.token
        this.masqueradeValidTo = validTo.toISOString()
        await userStore.onUserSwitch()
        return true
      } catch (error) {
        // remove masquerade from query param
        return false
      }
    },
    async leaveMasquerade() {
      if (this.mode === 'masquerade') {
        this.mode = 'teacher'
        this.masqueradeUserId = 0
        const userStore = useUserStore()
        await userStore.onUserSwitch()
      }
    },
    async onReload() {
      // if query param contains masquerade, set mode to masquerade
      const urlParams = new URLSearchParams(window.location.search)
      const userId = urlParams.get('masquerade')
      if (Number(userId) && this.hasValidPersonalToken()) {
        await this.ensureMasquerade(Number(userId))
      } else if (this.mode === 'masquerade') {
        this.mode = 'teacher'
      }
      // On reload mode should already be in session store but in case it isn't
      // try to guess the mode based on the available tokens
      if (this.hasValidPersonalToken()) {
        this.mode = this.mode || 'teacher'
      }
      if (this.hasValidStudentToken()) {
        this.mode = this.mode || 'student'
      }
    },
    async becomeUser(userId: number) {
      const userStore = useUserStore()
      this.mode = 'teacher'
      const response = await getMasqueradeToken(userId)
      const validTo = validSecondsToValidTo(response.validSeconds)
      this.teacherToken = response.token
      this.validTo = validTo.toISOString()
      await userStore.onUserSwitch()
    },
    setStudentToken(token: string, validTo: Date) {
      this.studentToken = token
      this.studentValidTo = validTo.toISOString()
      this.mode = 'student'
    },
    setTeacherToken(token: string, validTo: Date) {
      this.teacherToken = token
      this.validTo = validTo.toISOString()
      this.mode = 'teacher'
    },
    logoutStudent() {
      this.studentToken = null
      this.studentValidTo = null
    },
    logoutTeacher() {
      this.logoutStudent()
      this.teacherToken = null
      this.validTo = null
      this.masqueradeUserId = 0
      this.masqueradeToken = null
      this.masqueradeValidTo = null
    },
  },
})
