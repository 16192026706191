type PostOptions = {
  url: string
  newTab?: boolean
  data: Record<string, string>
}

export function PostRedirect(options: PostOptions) {
  const form = document.createElement('form')
  form.method = 'post'
  form.action = options.url
  form.style.display = 'none'

  if (options.newTab) {
    form.target = '_blank'
  }

  Object.entries(options.data).forEach(([key, value]) => {
    const input = document.createElement('input')
    input.name = key
    input.value = value
    form.appendChild(input)
  })

  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}
