import { http, httpShared, throwOnAxiosError } from './http'

export async function curriculumFindByCountryCode(locale) {
  const res = await http.get(`/v1/country/${locale}/node`)
  return res.data.curriculas
}

export async function curriculumGetById(id) {
  try {
    const res = await http.get(`/v1/me/curricula/${id}`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve curriculum')
  }
}

export async function getMyCurricula() {
  try {
    const res = await http.get(`/v1/me/curricula`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve curricula')
  }
}

export async function abilitiesGetByNodeId(id) {
  try {
    const res = await httpShared.get(`/v1/node/${id}/ability`)
    return res.data.abilities
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve abilities')
  }
}

// TODO: remove if not used
export async function pointTypesGetByNodeId(id) {
  try {
    const res = await http.get(`/v1/node/${id}/point`)
    return res.data.points
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve point types')
  }
}

export default { curriculumFindByCountryCode, curriculumGetById }
