export function ensureExamSettingsValid(settings: any, templateSettings: any) {
  const settingsCopy = JSON.parse(JSON.stringify(settings))
  const fillMissingProps = (target: any, source: any) => {
    Object.keys(source).forEach((key) => {
      if (typeof source[key] === 'object' && source[key] !== null) {
        if (Array.isArray(source[key])) {
          // Directly clone arrays from source to target if not present or not an array
          if (!Array.isArray(target[key])) {
            target[key] = [...source[key]]
          }
        } else {
          // Ensure the target has the key with an object value
          if (
            !target[key] ||
            typeof target[key] !== 'object' ||
            Array.isArray(target[key])
          ) {
            target[key] = {}
          }
          // Recursive call to fill missing props in the nested object
          fillMissingProps(target[key], source[key])
        }
      } else {
        // Fill missing primitive value
        if (!Object.prototype.hasOwnProperty.call(target, key)) {
          target[key] = source[key]
        }
      }
    })
  }
  fillMissingProps(settingsCopy, templateSettings)
  return settingsCopy
}
