import http, { throwOnAxiosError } from './httpApi'

export const API2 = {
  async getTeacherInfo(id) {
    try {
      const res = await http.get(`/teachers/${id}/info`)
      return res.data
    } catch (err) {
      throwOnAxiosError(err, 'Failed to get teacher info')
    }
  },
}
