import { createI18n, useI18n } from 'vue-i18n'
import { loadTranslations, uiLanguages } from './translations/loadTranslations'
import { enGB, it, nb, sv } from 'date-fns/locale'

let locale = 'en'

const fallbackLanguageHosts = (
  import.meta.env.VITE_FALLBACK_LANGUAGE_HOSTS || ''
)
  .split(',')
  .map((host) => {
    const [locale, hostName] = host.split(':')
    return { locale, hostName }
  })
  .reduce((acc, { locale, hostName }) => {
    acc[hostName] = locale
    return acc
  }, {})

const fallbackLocale = 'en'

if (Object.keys(fallbackLanguageHosts).includes(window.location.host)) {
  locale = fallbackLanguageHosts[window.location.host]
}

// check query param locale
const urlParams = new URLSearchParams(window.location.search)
const queryLocale = urlParams.get('locale')
if (queryLocale && uiLanguages.includes(queryLocale)) {
  locale = queryLocale
}

document.documentElement.lang = locale

// remove all language so we see keys
const translations =
  queryLocale === 'key' ? { sv: [], en: [] } : loadTranslations()

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale,
  fallbackLocale,
  useScope: 'global',
  messages: translations,
})

// Added to support a few special cases in KM exam facade
export const kmTranslate = (key, params) => {
  let string = translations.sv[key]
  if (!string) {
    string = translations.en[key]
  }
  if (!string) {
    string = key
  }
  if (params) {
    Object.keys(params).forEach((key) => {
      string = string.replace(`{${key}}`, params[key])
    })
  }
  return string
}

export const setGlobalLocale = (l) => {
  locale = l
  i18n.global.locale.value = locale
  document.documentElement.lang = locale
}

export const getLocalizedShortDate = (date) => {
  const options = {
    month: 'long',
    day: 'numeric',
  }
  return new Date(date).toLocaleDateString(locale, options)
}

export const getTs = (language) => {
  const _18n = createI18n({
    locale: language,
    fallbackLocale: 'en',
    messages: translations,
  })
  return { t: useI18n().t, tt: _18n.global.t }
}

export const getDateFnsLocaleModule = () => {
  const language = i18n.global.locale.value
  switch (language) {
    case 'it':
      return it
    case 'nb':
      return nb
    case 'sv':
      return sv
    case 'en':
      return enGB
    default:
      return enGB
  }
}

export const getLanguageNameInLocale = (code) => {
  const languageName = new Intl.DisplayNames([i18n.global.locale.value], {
    type: 'language',
  })
  return languageName.of(code)
}

export default i18n
