/**
 * pointCompositionArray: number[] = [easy, medium, difficult]
 *
 * Returns:
 * {
 *   icon: string, // 'easy', 'medium', 'difficult',
 *   label: string, // translation key
 *   weightedMeanValue: number, // used when sorting questions by difficulty
 * }
 */
import { Difficulty } from '@/constants'

export function getDifficulty(pointCompositionArray: number[]) {
  const weightedPointComposition = [0, 0, 0]
  let icon, label, decidedDifficulty

  weightedPointComposition[0] = pointCompositionArray[0] * 1 // e-point weight
  weightedPointComposition[1] = pointCompositionArray[1] * 2 // c-point weight
  weightedPointComposition[2] = pointCompositionArray[2] * 3 // a-point weight

  // n = total number of points
  const n =
    pointCompositionArray[0] +
    pointCompositionArray[1] +
    pointCompositionArray[2]

  // weighted mean diffulty for the question with e assigned weight 1, c assigned weight 2 and a assigned weight 3
  const weightedMeanValue =
    (weightedPointComposition[0] +
      weightedPointComposition[1] +
      weightedPointComposition[2]) /
    n

  if (weightedMeanValue < 1.21) {
    decidedDifficulty = 1
    icon = Difficulty.EASY
    label = Difficulty.EASY
  } else if (weightedMeanValue < 2.21) {
    decidedDifficulty = 2
    icon = Difficulty.MEDIUM
    label = Difficulty.MEDIUM
  } else {
    decidedDifficulty = 3
    icon = Difficulty.DIFFICULT
    label = Difficulty.DIFFICULT
  }

  const varianceSum =
    Math.pow(1 - decidedDifficulty, 2) * pointCompositionArray[0] + // e-points exists - add weighted varians from decided difficulty
    Math.pow(2 - decidedDifficulty, 2) * pointCompositionArray[1] + // c-points exists - add weighted varians from decided difficulty
    Math.pow(3 - decidedDifficulty, 2) * pointCompositionArray[2] // a-points exists - add weighted varians from decided difficulty

  const deviationFromDecidedDifficulty = Math.sqrt(varianceSum / n)

  if (
    (decidedDifficulty <= 2 && deviationFromDecidedDifficulty > 0.5) ||
    deviationFromDecidedDifficulty > 0.6 // Allow larger variance for the highest difficulty level
  ) {
    label = Difficulty.MIXED
  }

  return {
    icon, // 'easy', 'medium', 'difficult',
    label, // translation key
    weightedMeanValue, // used when sorting questions by difficulty
  }
}

export function insertInOrder(questions: any, question: any) {
  if (!questions.find((q: any) => q.id === question.id)) {
    const indexToInsert = questions.findIndex(
      (oldQ: any) => oldQ.difficulty > question.difficulty
    )
    if (indexToInsert >= 0) {
      questions.splice(indexToInsert, 0, question)
    } else {
      questions.push(question)
    }
  }
}
