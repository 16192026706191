import { defineStore } from 'pinia'
import { useTokenStore } from '@/stores/token'
import { useKMTokenStore } from '@/km/stores/token'
import { useUserStore } from '@/stores/user'

const BLANK_CURRICULUM: ExamCurriculum = {
  id: 0,
  name: '',
  subjects: [],
}

export const useSessionCacheStore = defineStore('app', {
  state: () => ({
    loadedCurricula: [] as Curriculum[],
    loadedCurriculum: BLANK_CURRICULUM as ExamCurriculum,
  }),
  getters: {
    curricula(state) {
      return state.loadedCurricula
    },
    curriculum(state): ExamCurriculum {
      return state.loadedCurriculum
    },
  },
  actions: {
    async updateCurricula() {
      const userStore = useUserStore()
      this.loadedCurricula = await userStore.facade.getMyCurricula()
    },
    async updateCurriculum() {
      const userStore = useUserStore()
      this.loadedCurriculum =
        (await userStore.getCurrentCurriculum()) || BLANK_CURRICULUM
    },
    async updateCache() {
      const km = useUserStore().km
      const tokenStore = km ? useKMTokenStore() : useTokenStore()

      const shouldRun = tokenStore.hasValidTeacherToken()

      if (shouldRun && useUserStore().isTeacher) {
        await this.updateCurriculum()
        await this.updateCurricula()
      }
    },
    async onLogin() {
      await this.updateCache()
    },
    async onReload() {
      await this.updateCache()
    },
    clear() {
      this.loadedCurriculum = BLANK_CURRICULUM
    },
  },
})
