import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'
import { PageSize, FIRST_PAGE_INDEX } from '@/constants'

type QueryParams = {
  query: string
  type: string
  status: string
  courseIds: string[]
  groupIds: string[]
  page: number
  pageSize: number
  forceSearch: number
}

type Store = {
  params: QueryParams
  page: number
  pageSize: number
}

const IGNORED_PARAMS = ['query', 'page', 'pageSize', 'forceSearch']

const BLANK_PARAMS = {
  query: '',
  type: '',
  status: '',
  courseIds: [],
  groupIds: [],
  forceSearch: 0,
}

const initialStore = (): Store => ({
  params: JSON.parse(JSON.stringify(BLANK_PARAMS)),
  page: FIRST_PAGE_INDEX,
  pageSize: PageSize.NORMAL,
})

export const useExamsStore = defineStore('exams', {
  state: () => ({
    store: useSessionStorage('examsSearch', initialStore()),
  }),
  getters: {
    search: (state) => {
      if (!state.store) {
        state.store = initialStore()
      }
      return state.store
    },
    queryParams(): QueryParams {
      return {
        query: this.search.params.query,
        type: this.search.params.type,
        status: this.search.params.status,
        courseIds: this.search.params.courseIds,
        groupIds: this.search.params.groupIds,
        page: this.search.page,
        pageSize: this.search.pageSize,
        forceSearch: this.search.params.forceSearch,
      }
    },
    numberOfQueryParams(): number {
      const numFilter = Object.entries(this.search.params).reduce(
        (acc: number, [key, value]) => {
          if (
            !IGNORED_PARAMS.includes(key) &&
            (Array.isArray(value) ? value.length > 0 : value)
          ) {
            return acc + 1
          }
          return acc
        },
        0
      )
      return numFilter
    },
  },
  actions: {
    forceSearch() {
      this.search.params.forceSearch++
    },
    setPageIndex(index: number) {
      this.search.page = index
    },
    setPageSize(size: number) {
      this.search.pageSize = size
    },
    clear() {
      this.store = initialStore()
    },
  },
})
