<!-- License note: file modified by Atanas F. Nikolov -->

<template>
  <component :is="as" :class="cls">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'
import { css } from '@emotion/css'
import stcss from '@styled-system/css'
import { themeKey, defaultTheme } from './Theme'
import utils from '..'

type Style = {
  [key: string]: string | number | ((theme: Theme) => any)
}

type Props = {
  as?: object | string
  style?: Style
  defaultName?: string
}

const props = withDefaults(defineProps<Props>(), {
  as: 'div',
  style: () => ({}),
  defaultName: '',
})

const componentName = utils.getStyledComponentName(props.defaultName)

const theme = inject(themeKey, defaultTheme)

const style = computed(() => {
  return stcss(props.style)({ theme })
})

const cls = computed(() => css(style.value, `label:${componentName};`))
</script>
