import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'

type PersonalDetails = {
  firstName: string
  lastName: string
  subjects: string[]
}

type Organization = {
  type?: string
  name?: string
  city?: string
  studentCount?: number
}

type SSO = {
  isSSO: boolean
  type: 'google' | 'microsoft'
}

type LoginEntrypointMessage = {
  type: 'success' | 'error'
  text: string
}

type SignupState = {
  email: string
  code: string
  countryName: string
  countryCode: string
  hasRegions: boolean
  createSchools: boolean
  regionId?: string
  regionName?: string
  schoolId: string
  organization: Organization
  personalDetails: PersonalDetails
  loginEntrypointMessage: LoginEntrypointMessage | null
  SSO?: SSO
}

export const useSignupStore = defineStore('signup', {
  state: () => ({
    signup: useSessionStorage('signup', {} as SignupState),
  }),
  getters: {
    getEmail: (state) => state.signup.email,
  },
  actions: {
    setEmail(email: string) {
      this.signup.email = email
    },
    setSignupCode(signupCode: string) {
      this.signup.code = signupCode
    },
    setCountryName(countryName: string) {
      this.signup.countryName = countryName
    },
    setCountryCode(countryCode: string) {
      this.signup.countryCode = countryCode
    },
    setHasRegions(hasRegions: boolean) {
      this.signup.hasRegions = hasRegions
    },
    setCreateSchools(createSchools: boolean) {
      this.signup.createSchools = createSchools
    },
    setRegion(regionId?: string, regionName?: string) {
      this.signup.regionId = regionId
      this.signup.regionName = regionName
    },
    setSchoolId(schoolId: string) {
      this.signup.schoolId = schoolId
    },
    setOrganization(organization: Organization) {
      this.signup.organization = organization
    },
    clearOrganization() {
      this.signup.organization = {}
    },
    clearSchoolId() {
      this.signup.schoolId = ''
    },
    setPersonalDetails(details: PersonalDetails) {
      this.signup.personalDetails = details
    },
    setLoginEntrypointMessage(message: LoginEntrypointMessage | null) {
      this.signup.loginEntrypointMessage = message
    },
    setSSO(sso: SSO) {
      this.signup.SSO = sso
    },
    clearSSO() {
      delete this.signup.SSO
    },
    clearAllExceptEntrypointMessage() {
      this.signup = {
        loginEntrypointMessage: this.signup.loginEntrypointMessage,
      }
    },
    clearAll() {
      this.signup = null
    },
  },
})
