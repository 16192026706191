import en from '@/translations/en.json'
import sv from '@/translations/sv.json'
// import it from '@/translations/it.json'
import nb from '@/translations/nb.json'
import da from '@/translations/da.json'
import fr from '@/translations/fr.json'
import de from '@/translations/de.json'
import es from '@/translations/es.json'

function transform(languageData) {
  return languageData.reduce((acc, item) => {
    if (item.definition instanceof Object) {
      acc[item.term] = `${item.definition.one} | ${item.definition.other}`
      return acc
    }
    acc[item.term] = item.definition
    return acc
  }, {})
}

export const uiLanguages = ['en', 'sv', 'nb']

export function loadTranslations() {
  return {
    en: transform(en),
    sv: transform(sv),
    // it: transform(it),
    nb: transform(nb),
    da: transform(da),
    fr: transform(fr),
    de: transform(de),
    es: transform(es),
  }
}
