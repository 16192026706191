import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'

// This store only exists because render-question does not allow
// for local behaviour when rendering questions

// Never add anything here without a good reason
export const useGlobalStore = defineStore('global', {
  state: () => ({
    questionLanguage: useLocalStorage('questionLanguage', 'en'),
  }),
  actions: {
    setQuestionLanguage(lang: string) {
      this.questionLanguage = lang
    },
  },
})
