const delTypes = [
  { id: 1, kortsvar: true, calculator: false, autocorr: true },
  { id: 2, kortsvar: true, calculator: true, autocorr: true },
  { id: 3, kortsvar: true, calculator: false, digital: true },
  { id: 4, kortsvar: true, calculator: true, digital: true },
  { id: 5, kortsvar: true, calculator: false, digital: false },
  { id: 6, kortsvar: true, calculator: true, digital: false },
  { id: 7, kortsvar: false, calculator: false, digital: true },
  { id: 8, kortsvar: false, calculator: true, digital: true },
  { id: 9, kortsvar: false, calculator: false, digital: false },
  { id: 10, kortsvar: false, calculator: true, digital: false },
]

export function getDelTypes(examType) {
  const result = delTypes.find((delType) => delType.id === examType)
  if (result) {
    return result
  } else {
    return delTypes[9]
  }
}
