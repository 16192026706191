import ct from 'countries-and-timezones'
import i18n from '@/i18n'
import { format } from 'date-fns'

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const guessCountryCode = () => {
  if (timeZone) {
    return ct.getCountryForTimezone(timeZone)?.id || ''
  }
  return ''
}

export const getLocaleDate = (
  date,
  languageCode = i18n.global.locale.value,
  format = 'short'
) => {
  if (!date) {
    return ''
  }

  return new Date(date).toLocaleDateString(languageCode, {
    timeZone,
    year: 'numeric',
    month: format,
    day: 'numeric',
  })
}

export const getDateToYYYYMMDD = (newDate) => {
  return format(newDate, 'yyyy-MM-dd')
}
