import http, { throwOnAxiosError } from './httpApi'

// export async function questionSearchByCourseId(courseId, params) {
export async function questionSearch(
  momentIds,
  vMomentIds,
  courseId,
  bookKey,
  params
) {
  const data = { tags: [], abilities: [], types: [] }
  if (vMomentIds.length !== 0) {
    data.vMomentIds = vMomentIds
  } else if (momentIds.length !== 0) {
    data.momentIds = momentIds
  } else {
    data.bookId = bookKey
  }
  data.courseId = courseId

  if (params.sortBy) {
    const [field, direction] = params.sortBy.split('-')
    data.orderBy = [{ field, direction }]
  }
  if (typeof params.autocorrect === 'boolean') {
    data.tags.push(params.autocorrect ? 'autocorrect' : 'no:autocorrect')
  }
  if (typeof params.calculator === 'boolean') {
    data.tags.push(params.calculator ? 'calculator' : 'no:calculator')
  }
  if (params.abilities || params.pointTypes) {
    const abilities = params.abilities || ['']
    const difficulties = params.pointTypes || ['']
    abilities.forEach((a) => {
      difficulties.forEach((d) => {
        data.abilities.push({
          ...(a !== '' && { ability: a }),
          ...(d !== '' && { difficulty: d }),
        })
      })
    })
  }
  if (params.type) {
    data.type = params.type
  }
  if (params.literature) {
    data.literature = params.literature
  }
  if (params.visibility) {
    data.visibility = params.visibility
  }
  if (typeof params.studentAccess === 'boolean') {
    data.studentopen = params.studentAccess ? 'non_blocked' : 'blocked'
  }
  if (params.query) {
    data.query = params.query
  }
  if (typeof params.page === 'number' && params.page >= 0) {
    data.page = params.page
  }
  if (typeof params.pageSize === 'number' && params.pageSize > 0) {
    data.pageSize = params.pageSize
  }
  try {
    return (
      await http.post('/cognitive/search', {
        ...data,
        _METHOD: 'GET',
      })
    ).data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to search')
  }
}
