import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'
import { PageSize, FIRST_PAGE_INDEX, SORT_BY_NAME_KEY } from '@/constants'

type FacetItem = {
  id: string | number
  name: string
}

type MinMax = { min: number; max: number }

type Facets = {
  book: FacetItem[]
  sources: FacetItem[]
  chapters: FacetItem[]
  points: MinMax
  questions: MinMax
  // abilities: FacetItem[]
}

type QueryParams = {
  query: string
  sortBy: string
  courseId: string
  autocorrect: string
  calculator: string
  subChapters: string[]
  points: number[]
  questions: number[]
  page: number
  pageSize: number
  forceSearch: number
  // abilities: string[]
}

type Store = {
  params: QueryParams
  facets: Facets | null
  page: number
  pageSize: number
  tableSortBy: { field: string; order: number }
}

const IGNORED_PARAMS = ['query', 'sortBy', 'page', 'pageSize', 'forceSearch']

const BLANK_PARAMS = {
  query: '',
  sortBy: SORT_BY_NAME_KEY,
  autocorrect: '',
  calculator: '',
  // abilities: [],
  subChapters: [],
  courseId: '',
  points: [],
  questions: [],
  forceSearch: 0,
}

const initialStore = (): Store => ({
  params: JSON.parse(JSON.stringify(BLANK_PARAMS)),
  facets: null,
  page: FIRST_PAGE_INDEX,
  pageSize: PageSize.NORMAL,
  tableSortBy: { field: 'name', order: -1 },
})

export const useExamLibraryStore = defineStore('examLibrary', {
  state: () => ({
    store: useSessionStorage('examSearch', initialStore()),
  }),
  getters: {
    search: (state) => {
      if (!state.store) {
        state.store = initialStore()
      }
      return state.store
    },
    queryParams(): QueryParams {
      return {
        query: this.search.params.query,
        sortBy: this.search.params.sortBy,
        courseId: this.search.params.courseId,
        autocorrect: this.search.params.autocorrect,
        calculator: this.search.params.calculator,
        points: this.search.params.points,
        questions: this.search.params.questions,
        subChapters: this.search.params.subChapters,
        forceSearch: this.search.params.forceSearch,
        //   abilities: this.search.params.abilities,
        page: this.search.page,
        pageSize: this.search.pageSize,
      }
    },
    numberOfQueryParams(): number {
      const numFilter = Object.entries(this.search.params).reduce(
        (acc: number, [key, value]) => {
          if (
            !IGNORED_PARAMS.includes(key) &&
            (Array.isArray(value) ? value.length > 0 : value)
          ) {
            return acc + 1
          }
          return acc
        },
        0
      )
      return numFilter
    },
  },
  actions: {
    forceSearch() {
      this.search.params.forceSearch++
    },
    setSortBy(field: string | undefined, order: number | null) {
      // order has three states: 1, -1, null (null means no sort)
      const sortOrder = order === 1 ? 'asc' : 'desc'
      const sortField = field || 'name'
      this.search.params.sortBy = `${sortField}-${sortOrder}`
      this.search.tableSortBy = {
        field: sortField,
        order: order === 1 ? 1 : -1,
      }
    },
    setPageIndex(index: number) {
      this.search.page = index
    },
    setPageSize(size: number) {
      this.search.pageSize = size
    },
    setFacets(facets: any) {
      this.search.facets = facets
    },
    setPoints(points: number[]) {
      const [min, max] = points
      if (min === 0 && max === 100) {
        points = []
      }
      this.search.params.points = points
    },
    setNumQuestions(questions: number[]) {
      const [min, max] = questions
      if (min === 0 && max === 100) {
        questions = []
      }
      this.search.params.questions = questions
    },
    clear() {
      this.store = initialStore()
    },
  },
})
