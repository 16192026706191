<template>
  <div style="display: none" />
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useCookies } from '@vueuse/integrations/useCookies'
import { useState } from 'vue-gtag-next'

const ANALYTICS_COOKIE = 'cookielawinfo-checkbox-analytics'

const { isEnabled } = useState()

const cookies = useCookies([ANALYTICS_COOKIE])

onMounted(() => {
  const analytics = cookies.get(ANALYTICS_COOKIE)
  if (analytics && analytics === 'yes') {
    isEnabled.value = true // Enable google analytics
  } else {
    isEnabled.value = false // Disable google analytics
  }
})
</script>
