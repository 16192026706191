import { logException } from './logger'
import { ApplicationError } from './ApplicationError'

export class UserFacingError extends ApplicationError {
  loggingEnabled
  status: number | undefined
  data: object | undefined
  constructor(
    err: any,
    message: string,
    loggingEnabled = true,
    translationKey = 'generic.error.server',
    status: undefined | number = undefined
  ) {
    super(message, translationKey)
    this.name = 'UserFacingError'
    this.loggingEnabled = loggingEnabled
    this.status = status
    this.data = err.response?.data?.data || {}

    if (err.code === 'ERR_NETWORK') {
      this.translationKey = 'generic.error.network'
    }
    if (this.loggingEnabled) {
      logException(err)
    }
  }
}
