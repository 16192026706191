import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import { createPinia } from 'pinia'

import PrimeVue from 'primevue/config'
import Lara from '@primevue/themes/lara'
import { laraPreset } from './laraPreset'
import { definePreset } from '@primevue/themes'
import Ripple from 'primevue/ripple'
import ToastService from 'primevue/toastservice'

import { vTooltip } from 'floating-vue'

import i18n from './i18n'
import { Vue3Mq } from 'vue3-mq'

import { MotionPlugin } from '@vueuse/motion'

import DOMPurify from 'dompurify'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import VueGtag from 'vue-gtag-next'
import jQuery from 'jquery'
import RenderMath from '@teachiq/render-math'
import FroalaEditor from '@teachiq/froala-editor'

// Trigger side effects from featureFlags store
import '@/stores/featureFlags'

let bugsnagVue: any

if (import.meta.env.VITE_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    collectUserIp: false,
    releaseStage: import.meta.env.MODE,
    enabledReleaseStages: ['production', 'staging'],
    ...(import.meta.env.MODE === 'development' && { logger: null }),
    onError: (event) => {
      if (event.errors[0].errorClass === 'UserFacingError') {
        // Commonly 401 errors leak from onMounted hooks and we will assume they can be ignored
        return false
      }
      return true
    },
    redactedKeys: ['props'],
  })
  bugsnagVue = Bugsnag.getPlugin('vue')
}

const katexPromise = RenderMath.setModeAsync('katex')

const MyPreset = definePreset(Lara, laraPreset)

const app = createApp(App)
  .directive('tooltip', vTooltip)
  .directive('ripple', Ripple)
  .use(VueGtag, {
    isEnabled: false,
    property: {
      id: import.meta.env.VITE_GA_ID,
    },
    useDebugger: true,
  })
  .use(router)
  .use(i18n)
  .use(PrimeVue, {
    theme: {
      preset: MyPreset,
      options: {
        darkModeSelector: '.my-app-dark',
        cssLayer: false,
      },
    },
    ripple: false,
  })
  .use(ToastService)
  .use(MotionPlugin)
  .use(Vue3Mq, {
    preset: 'bootstrap5',
  })
  .use(createPinia())

app.directive('sanitize-html', (el, binding) => {
  el.innerHTML = DOMPurify.sanitize(binding.value)
})

if (bugsnagVue) {
  app.use(bugsnagVue)
}
app.mixin({
  methods: {
    __: (k: string, p: any) => window.K$.__(k, p),
  },
})

// FroalEditor depends on window.jQuery
window.jQuery = jQuery
app.use(FroalaEditor)

app.provide('katexPromise', katexPromise)

app.mount('#app')
