// Bootstrap5 breakpoints based on @min-width used for js media queries via vue3-mq
// const breakpoints = {
//   xs: '0px',
//   sm: '576px',
//   md: '768px',
//   lg: '992px',
//   xl: '1200px',
//   xxl: '1400px',
// }

// CSS media queries based on @min-width
// mobile: 0 - 767px
// tablet: 768 - 991px
// desktop: 992 - ...
const breakpoints = ['768px', '992px']

export default breakpoints
