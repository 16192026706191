import { defineStore } from 'pinia'
import { useLocalStorage, StorageSerializers } from '@vueuse/core'
import { BLANK_EXAM } from './exam'

export const usePrintStore = defineStore('print', {
  state: () => ({
    loadedExam: useLocalStorage('print', null as Exam | null, {
      serializer: StorageSerializers.object,
    }),
  }),
  getters: {
    exam(): Exam {
      if (!this.loadedExam) {
        // It is ok to load a blank exam, we expect the real exam to load momentarily
        return BLANK_EXAM
      }
      return this.loadedExam
    },
  },
  actions: {
    setExam(exam: Exam) {
      this.loadedExam = JSON.parse(JSON.stringify(exam))
    },
    clear() {
      this.loadedExam = null
    },
  },
})
