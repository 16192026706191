import { addPoints } from './points.js';
/* exported for testing */
export function npECAClassicRow(thresholds, points) {
    const e = points[0];
    const c = points[1];
    const a = points[2];
    return [
        {
            levelPoints: e,
            pass: {
                min: Math.ceil(e * thresholds.e.green),
                disabled: true,
            },
            low: {
                min: Math.ceil(e * thresholds.e.yellow),
                disabled: true,
            },
        },
        {
            levelPoints: c,
            pass: {
                min: Math.ceil(thresholds.c.green_factor_ec * (e + c)),
                onLevelOrHigher: Math.ceil(thresholds.c.green_factor_c * c),
                disabled: false,
            },
            low: {
                min: Math.ceil(thresholds.c.yellow_factor_e * e),
                onLevelOrHigher: Math.ceil(thresholds.c.yellow_factor_c * c),
                disabled: false,
            },
        },
        {
            levelPoints: a,
            pass: {
                min: Math.ceil(thresholds.a.green_factor_ec * (e + c) +
                    thresholds.a.green_factor_a * a),
                onLevelOrHigher: Math.ceil(thresholds.a.green_factor_a * a),
                disabled: false,
            },
            low: {
                min: Math.ceil(thresholds.a.yellow_factor_ec * (e + c)),
                onLevelOrHigher: Math.ceil(thresholds.a.yellow_factor_a * a),
                disabled: false,
            },
        },
    ];
}
function npECAClassic(thresholds, pointsPerAbility) {
    return pointsPerAbility.map((points) => {
        return npECAClassicRow(thresholds, points);
    });
}
export function simpleRow(thresholds, ecaPoints) {
    const points = ecaPoints[0] + ecaPoints[1] + ecaPoints[2];
    return [
        {
            levelPoints: ecaPoints[0],
            pass: {
                min: Math.round(points * thresholds[1]),
                disabled: true,
            },
            low: {
                min: Math.round(points * thresholds[0]),
                disabled: true,
            },
        },
        {
            levelPoints: ecaPoints[1],
            pass: {
                min: Math.round(points * thresholds[3]),
                disabled: true,
            },
            low: {
                min: Math.round(points * thresholds[2]),
                disabled: true,
            },
        },
        {
            levelPoints: ecaPoints[2],
            pass: {
                min: Math.round(points * thresholds[5]),
                disabled: true,
            },
            low: {
                min: Math.round(points * thresholds[4]),
                disabled: true,
            },
        },
    ];
}
function simple(thresholds, pointsPerAbility) {
    return pointsPerAbility.map((points) => {
        return simpleRow(thresholds, points);
    });
}
function getPointsPerAbility(connectedAbilities, criterias) {
    // gather data on all questions in the exam
    const highestGroupIndex = Math.max(...Object.values(connectedAbilities));
    const pointsPerGroup = Array.from({ length: highestGroupIndex + 1 }).map(() => [0, 0, 0]);
    criterias.forEach((criteria) => {
        const abilityIndex = connectedAbilities[criteria.abilityKey];
        pointsPerGroup[abilityIndex][criteria.pointIndex]++;
    });
    const totalPoints = pointsPerGroup.reduce((acc, val) => {
        return addPoints(acc, val);
    }, [0, 0, 0]);
    return [...pointsPerGroup, totalPoints];
}
export function calculateExamLimits(algorithm, thresholds, connectedAbilities, criterias) {
    const pointsPerAbility = getPointsPerAbility(connectedAbilities, criterias);
    switch (algorithm) {
        case 'simple':
            return simple(thresholds, pointsPerAbility);
        case 'np_eca_classic':
        default:
            return npECAClassic(thresholds, pointsPerAbility);
    }
}
function getPointsPerMoment(criterias, sorting) {
    // gather data on all questions in the exam
    const pointsPerMoment = criterias.reduce((acc, criteria) => {
        var _a;
        if (!acc.has(criteria.subchapterId)) {
            const sort = ((_a = sorting.find((s) => s.subchapterId === criteria.subchapterId)) === null || _a === void 0 ? void 0 : _a.globalSort) || 100000000;
            acc.set(criteria.subchapterId, {
                sort,
                points: [0, 0, 0],
            });
        }
        const { sort, points } = acc.get(criteria.subchapterId);
        const availablePoints = [0, 0, 0];
        availablePoints[criteria.pointIndex] = criteria.points;
        acc.set(criteria.subchapterId, {
            sort,
            points: addPoints(points, availablePoints),
        });
        return acc;
    }, new Map());
    const pointsPerMomentArray = Array.from(pointsPerMoment.values())
        .sort((a, b) => a.sort - b.sort)
        .map((moment) => moment.points);
    const totalPoints = pointsPerMomentArray.reduce((acc, val) => {
        return addPoints(acc, val);
    }, [0, 0, 0]);
    return [...pointsPerMomentArray, totalPoints];
}
export function calculateExamMomentLimits(criterias, sorting) {
    const pointsPerMoment = getPointsPerMoment(criterias, sorting);
    const thresholds = {
        e: {
            yellow: 0.5,
            green: 0.72,
        },
        c: {
            yellow_factor_e: 0.75,
            yellow_factor_c: 0.33,
            green_factor_ec: 0.72,
            green_factor_c: 0.59,
        },
        a: {
            yellow_factor_ec: 0.75,
            yellow_factor_a: 0.3,
            green_factor_ec: 0.79,
            green_factor_a: 0.56,
        },
    };
    return npECAClassic(thresholds, pointsPerMoment);
}
