const colors = {
  'primary-text': '#373f41', // Use whenever you would use black for headings & body text

  green50: '#EDF7F0',
  green100: '#C6E7D0',
  green200: '#A5D9B6',
  green300: '#6BC288',
  green400: '#49B06B',
  green500: '#0DA057',
  green600: '#0B884A',
  green700: '#09713E',
  green800: '#085930',
  green900: '#064224',
  green950: '#042A17',

  gray50: '#F9F9F9',
  gray100: '#EEEEEE',
  gray200: '#E5E5E5',
  gray300: '#D8D8D8',
  gray400: '#C8C8C8',
  gray500: '#A6A6A6',
  gray600: '#949494',
  gray700: '#757575',
  gray800: '#515151',
  gray900: '#333333',
  gray950: '#262626',

  cyan50: '#F1FAFE',
  cyan100: '#D5F1FB',
  cyan200: '#BAE8F8',
  cyan300: '#99DDF5',
  cyan400: '#74D1F1',
  cyan500: '#3DBEEB',
  cyan600: '#16A4D6',
  cyan700: '#0B7499',
  cyan800: '#045572',
  cyan900: '#023B4F',
  cyan950: '#022531',

  blue50: '#F4F7FB',
  blue100: '#E2E9F3',
  blue200: '#C1D0E6',
  blue300: '#96B0D5',
  blue400: '#6D92C5',
  blue500: '#4977B6',
  blue600: '#3D6397',
  blue700: '#314F79',
  blue800: '#243B5A',
  blue900: '#1A2B41',
  blue950: '#101A28',

  red50: '#FEF1F1',
  red100: '#FBD0D0',
  red200: '#FBACAC',
  red300: '#FA8585',
  red400: '#F74545',
  red500: '#E60A0A',
  red600: '#C80909',
  red700: '#A10707',
  red800: '#730202',
  red900: '#500202',
  red950: '#370101',

  white: '#ffffff',

  error: '#F74545', // Error messages and outlines on components when something goes wrong
  warning: '#e66b00', // Warning color.

  'primary-cta': '#0DA057', // Primary CTA color
  success: '#11ab5e', // Used to convey success on smaller things like icons, checkboxes etc. Avoid using this color with text, in those cases the darker green would be preferable.

  /* Different Scale of Blue */
  'primary-brand': '#152235', // Primary brand color and primary blue.
  'primary-link': '#4977B6',
  'primary-bg': '#F4F7FB', // Used as the main background-color in the app.
}

export default colors
