import { http, throwOnAxiosError } from './http'

export async function attachmentsGet(attachmentIds: number[]) {
  try {
    const res = await http.post(`/v1/attachments/bulk`, {
      attachmentIds,
    })
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve question attachmens by id')
  }
}
