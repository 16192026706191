import http, { throwOnAxiosError } from './httpApi'
import { UserFacingError } from '@/utils/UserFacingError'

const baseURL = import.meta.env.VITE_KM_API_URL
const axiosConfig = {
  headers: {
    Accept: 'application/json, text/javascript, */*',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  baseURL,
}

export const API = {
  async request(apiUrlAxios, paramObj) {
    const params = new URLSearchParams()
    for (const key in paramObj) {
      if (!Array.isArray(paramObj[key])) {
        params.append(key, paramObj[key])
      } else {
        paramObj[key].map((x) => {
          params.append(`${key}[]`, x)
        })
        if (paramObj[key].length === 0) params.append(`${key}[]`, '')
      }
    }

    return http.post(apiUrlAxios, params, axiosConfig).catch((err) => {
      throwOnAxiosError(err, 'Failed to get data: ' + apiUrlAxios)
    })
  },
  async requestWithoutErrorHandling(apiUrlAxios, paramObj) {
    const params = new URLSearchParams()
    for (const key in paramObj) {
      if (!Array.isArray(paramObj[key])) {
        params.append(key, paramObj[key])
      } else {
        paramObj[key].map((x) => {
          params.append(`${key}[]`, x)
        })
        if (paramObj[key].length === 0) params.append(`${key}[]`, '')
      }
    }

    return http.post(apiUrlAxios, params, axiosConfig)
  },

  async getUppgift(questionId) {
    const apiUrlAxios = '/uppgifter/' + questionId + '?includeTests=true'

    const params = {
      fields:
        'uppgiftid,kortsvar,digitalaverktyg,epoints,cpoints,apoints,pointcomposition,htmlarr,solutionsource,litids,autocorr,solstat,momentid,teacherid,kommentar,studentopen,blockthis',
      limit: 1,
      _METHOD: 'GET',
    }
    return API.request(apiUrlAxios, params)
  },
  async getUppgifter(questionIds) {
    const apiUrlAxios = '/uppgifter/' + 0

    const params = {
      fields:
        // ks,version,chosen,e,c,a finns ej
        'uppgiftid,autocorr,digitalaverktyg,kortsvar,epoints,cpoints,apoints,pointcomposition,htmlarr,solutionsource,litids,studentopen,uw,uh,bw,bh,clone,clonedisp,ks,e,e0,e1,e2,e3,e4,e5,e6,e7,e8,e9,e10,e11,e12,c,c0,c1,c2,c3,c4,c5,c6,c7,c8,c9,c10,c11,c12,a,a0,a1,a2,a3,a4,a5,a6,a7,a8,a9,a10,a11,a12',
      comma_questions: questionIds.join(','),
      _METHOD: 'GET',
    }
    return API.request(apiUrlAxios, params)
  },

  async getQuestion(id) {
    const apiUrlAxios = '/tasks/' + id
    const params = {
      fields:
        // 'uppgiftid,momentid,teacherid,namn,kortsvar,digitalaverktyg,epoints,cpoints,apoints,e0,e1,e2,e3,e4,e5,e6,c0,c1,c2,c3,c4,c5,c6,a0,a1,a2,a3,a4,a5,a6,pointcomposition,uppgiftsource,bedomningsource,htmlarr,solutionsource,studentopen,dq,uq,uw,uh,bw,bh,specialtask,blockthis,autocorr,clone,clonedisp,randomnumber,kommentar,bigdate,qcheck,solstandarddev,solstat,shtmlarr,e7,e8,e9,e10,e11,e12,c7,c8,c9,c10,c11,c12,a7,a8,a9,a10,a11,a12,litids,v',
        'uppgiftid,momentid,teacherid,namn,kortsvar,digitalaverktyg,epoints,cpoints,apoints,e0,e1,e2,e3,e4,e5,e6,c0,c1,c2,c3,c4,c5,c6,a0,a1,a2,a3,a4,a5,a6,pointcomposition,htmlarr,solutionsource,studentopen,specialtask,blockthis,autocorr,clone,clonedisp,randomnumber,kommentar,bigdate,solstandarddev,solstat,shtmlarr,e7,e8,e9,e10,e11,e12,c7,c8,c9,c10,c11,c12,a7,a8,a9,a10,a11,a12,litids,v',
      _METHOD: 'GET',
    }
    return API.request(apiUrlAxios, params)
  },
  async isQuestionUsedInExams(id) {
    const apiUrlAxios = `tasks/${id}/used_in_tests?checkExamNet=true`
    const params = {
      _METHOD: 'GET',
    }
    return API.request(apiUrlAxios, params)
  },
  async getTeacher(id) {
    const apiUrlAxios = '/teachers/' + id
    const params = {
      fields:
        'teacherid,schoolid,lastname,firstname,teacheremail,subjects,thumbs,msg,modalmsg,regbigdate,courses,advsettings,teacherstatus,lastlogin,trust,allow_email',
      _METHOD: 'GET',
    }
    return API.request(apiUrlAxios, params)
  },
  async getClass(id, teacherId) {
    const apiUrlAxios = '/classes/' + id
    const params = {
      fields:
        'classid, classname, courseend, courseid, coursecode, classyear, allowedcontent, repcontent, teacherid, classtype, schoolid, book, creationdate, advsettings, active',
      'dataargs[teacherid]': String(teacherId),
      _METHOD: 'GET',
    }
    return API.request(apiUrlAxios, params)
  },
  async getClasses(id, subjectId) {
    const apiUrlAxios = '/teachers/' + id
    const params = {
      fields:
        'teacherid,schoolid,lastname,firstname,teacheremail,subjects,thumbs,msg,modalmsg,regbigdate,courses,advsettings,teacherstatus,lastlogin,trust,allow_email',
      getallclasses: subjectId,
      _METHOD: 'GET',
    }
    return API.request(apiUrlAxios, params)
  },
  async getClassesAndSharedClasses(id, subjectId) {
    const apiUrlAxios = '/v2/teachers/' + id + '/classes'
    const params = {
      ...(subjectId && { subjectId }),
      _METHOD: 'POST',
    }
    return API.request(apiUrlAxios, params)
  },
  async getQuestions(courseId, momentIds) {
    const apiUrlAxios = '/tasks/new'
    const params = {
      commacourses: courseId,
      commamoments: momentIds,
      limit: 50,
      noBlockFilter: 1,
      sort: '-uppgiftid',
      _METHOD: 'GET',
    }
    return API.request(apiUrlAxios, params)
  },
  async getLiteratureInfo(id) {
    const apiUrlAxios = '/literatures/' + id
    const params = {
      fields: 'id,fulltitle,texttype,html,author',
      offset: 0,
      limit: 1,
      _METHOD: 'GET',
    }
    return API.request(apiUrlAxios, params)
  },
  async getCourse(courseId) {
    const apiUrlAxios = '/courses/' + courseId
    const params = {
      fields:
        'courseid,coursecode,coursename,coursetype,coursesettings,subjectid,formagor,connectformagor,books,formula,defaultrep,status',
      _METHOD: 'GET',
    }
    return API.request(apiUrlAxios, params)
  },
  async getExtraInfoExam(testId) {
    const apiUrlAxios = '/tests/load/' + testId
    const params = {
      fields:
        'active,testid,orgid,namn,datum,examcode,teacherid,schoolid,classid,courseid,autosort,ansoverridearr,titleoverridearr,limitarr,digitalt,timeest,digiopen,deltypes,testtype,overridepdf,cckey,uppgifter,bookkey,coursetype,advsettings,classblock',
      includeu: 1,
      limit: 1,
      _METHOD: 'GET',
    }
    return API.request(apiUrlAxios, params)
  },

  async refreshToken(token, password) {
    const apiUrlAxios = '/token/refresh'
    const params = {
      _METHOD: 'POST',
      token,
      password,
    }
    return API.requestWithoutErrorHandling(apiUrlAxios, params).catch((err) => {
      throw new UserFacingError(
        err,
        'Failed to login',
        false,
        'generic.wrongUsernameOrPassword'
      )
    })
  },
  async reportQuestion(questionId, message, { courseId, errortype, userid }) {
    const apiUrlAxios = '/reports'
    // get current local time in the format YYYYMMDDHHMM
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')

    const timestamp = year + month + day + hours + minutes

    const params = {
      _METHOD: 'POST',
      errortype,
      bigdate: timestamp,
      uppgiftid: questionId,
      courseid: courseId,
      studentorteacher: 't',
      userid,
      message,
      atgard: 0,
    }
    return API.request(apiUrlAxios, params)
  },

  /*
   savesoluid - question ID
   soltype -
     1 - image
     2 - youtube
   soldata - data
   userid - userid

   */
  async saveNewSolution(payload) {
    const apiUrlAxios = `/solution/${payload.savesoluid}`
    const params = {
      ...payload,
      _METHOD: 'POST',
    }
    return API.request(apiUrlAxios, params)
  },
}
