import { defineStore } from 'pinia'

const featureFlagsWithDomain = JSON.parse(
  import.meta.env?.VITE_FEATURE_FLAGS || '[]'
)
const featureFlags = featureFlagsWithDomain.find((group: any) => {
  if (group.domains) {
    return group.domains.some((domain: string) => {
      return window.location.hostname.indexOf(domain) > -1
    })
  }
  return window.location.hostname.indexOf(group.domain) > -1
})

if (featureFlags?.title) {
  document.title = featureFlags.title
}

export const useFeatureFlagsStore = defineStore('featureFlags', {
  state: () => ({
    domain: featureFlags?.domain || null,
    featureFlags: featureFlags?.flags || [],
  }),
  getters: {
    flags(): Array<string> {
      return this.featureFlags || []
    },
  },
  actions: {
    hasFeatureFlag(flag: string): boolean {
      return this.featureFlags?.includes(flag) || false
    },
  },
})
