import { http, throwOnAxiosError } from './http'

export async function gradeThresholdsGetbyCode(code) {
  try {
    const res = await http.get(`/v1/thresholds/${code}`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve grade threshold')
  }
}
