import { useGtag } from 'vue-gtag-next'

const gtag = useGtag()

export function registerEvent(event: string, category: string, label: string) {
  gtag.event(event, {
    event_category: category,
    event_label: label,
  })
}
