// License note: file modified by Atanas F. Nikolov

import { defineComponent, VueElement, createVNode, isVNode } from 'vue'
import HtmlElement from './HtmlElement.vue'

export default (el: string | VueElement, defaultName: string) =>
  (style: { [key: string]: any }) => {
    return defineComponent({
      setup(_, { slots }) {
        return () => {
          let _slot: any
          return createVNode(
            HtmlElement,
            {
              as: el,
              style,
              defaultName,
            },
            _isSlot((_slot = slots.default?.()))
              ? _slot
              : {
                  default: () => [_slot],
                }
          )
        }
      },
    })
  }

function _isSlot(s: any) {
  return (
    typeof s === 'function' ||
    (Object.prototype.toString.call(s) === '[object Object]' && !isVNode(s))
  )
}
