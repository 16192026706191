export function reindexQuestion(question: Question, type = 'latin'): void {
  const questionIndices = question.content.filter(
    (subquestion: any) => subquestion.isQuestion
  )

  if (questionIndices.length === 1) {
    questionIndices[0].number = ''
  } else {
    questionIndices.forEach((part: any, index: number) => {
      part.number = getNumber(index + 1, type)
    })

    question.content.forEach((part: any) => {
      if (!part.isQuestion) {
        part.number = ''
      }
    })
  }
}

function getNumber(index: number, type: string) {
  switch (type) {
    case 'roman':
      return `${toRomanNumber(index)})`
    case 'latin':
      return `${toLatinNumber(index)})`
    case 'number':
      return `${index}.`
    default:
      return `${toLatinNumber(index)})`
  }
}

const z = 122 // char code for 'z' UTF-16 (decimal)
const a = 97 // char code for 'a' UTF-16 (decimal)
const latinAlphabetLength = z - a + 1

const charToLatin = (intChar: number) => {
  if (intChar < 0) {
    return ''
  }

  return String.fromCharCode(a + intChar)
}

const toLatinNumber = (int: number): string => {
  if (typeof int !== 'number') {
    return ''
  }

  int--
  let str = ''

  if (int < latinAlphabetLength) {
    return charToLatin(int)
  }

  const iterations = Math.floor(int / latinAlphabetLength)
  const remainder = int % latinAlphabetLength

  str += charToLatin(iterations - 1)
  str += charToLatin(remainder)

  return str
}

const toRomanNumber = (int: number): string => {
  if (typeof int !== 'number') {
    return ''
  }

  const digits: Array<string> = String(+int).split('')
  const key: Array<string> = [
    '',
    'C',
    'CC',
    'CCC',
    'CD',
    'D',
    'DC',
    'DCC',
    'DCCC',
    'CM',
    '',
    'X',
    'XX',
    'XXX',
    'XL',
    'L',
    'LX',
    'LXX',
    'LXXX',
    'XC',
    '',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
  ]

  let roman = ''
  let i = 3

  while (i--) {
    const index = Number(digits.pop())
    const romanChar: string = key[+index + i * 10]

    roman = (romanChar || '') + roman
  }

  return Array(+digits.join('') + 1).join('M') + roman
}

export function getType(n: string): string {
  if (n === '1.') {
    return 'number'
  }
  if (n === 'I)') {
    return 'roman'
  }

  return 'latin'
}
