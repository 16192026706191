import { http, throwOnAxiosError } from './http'

export async function examsSearchForUser(params) {
  let queryParams = ''
  if (typeof params.page === 'number' && params.page >= 0) {
    queryParams += `page=${params.page}`
  }
  if (typeof params.pageSize === 'number' && params.pageSize > 0) {
    queryParams += `&pageSize=${params.pageSize}`
  }
  if (params.query) {
    queryParams += `&query=${params.query}`
  }
  if (params.type) {
    queryParams += `&type=${params.type}`
  }
  if (params.status) {
    queryParams += `&status=${params.status}`
  }
  if (params.courseIds) {
    queryParams += params.courseIds
      .map((course) => `&courseIds[]=${course}`)
      .join('&')
  }
  if (params.groupIds) {
    queryParams += params.groupIds
      .map((group) => `&groupIds[]=${group}`)
      .join('&')
  }

  try {
    const res = await http.get(`v1/me/exams?${queryParams}`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to search my exams')
  }
}

export async function examsSearchAll(params) {
  let queryParams = ''
  if (params.subjectId) {
    queryParams += `subjectId=${params.subjectId}`
  }
  if (params.courseId) {
    queryParams += `&courseId=${params.courseId}`
  }
  if (typeof params.page === 'number' && params.page >= 0) {
    queryParams += `&page=${params.page}`
  }
  if (params.sortBy) {
    const [field, direction] = params.sortBy.split('-')
    queryParams += `&orderBy[0][field]=${field}&orderBy[0][direction]=${direction}`
  }
  if (params.published) {
    queryParams += `&published=true`
  }

  if (params.points) {
    const [min, max] = params.points
    if ((min ?? null) && (max ?? null)) {
      queryParams += `&points[ge]=${min}&points[le]=${max}`
    } else if (min ?? null) {
      queryParams += `&points[ge]=${min}`
    } else if (max ?? null) {
      queryParams += `&points[le]=${max}`
    }
  }
  if (params.questions) {
    const [min, max] = params.questions
    if ((min ?? null) && (max ?? null)) {
      queryParams += `&questions[ge]=${min}&questions[le]=${max}`
    } else if (min ?? null) {
      queryParams += `&questions[ge]=${min}`
    } else if (max ?? null) {
      queryParams += `&questions[le]=${max}`
    }
  }
  if (params.myOrganization) {
    queryParams += `&myOrganization=true`
  }
  if (params.colleagueId) {
    queryParams += `&colleaguesIds=${params.colleagueId}`
  }
  if (params.own) {
    queryParams += `&own=true`
  }

  if (params.autocorrect) {
    queryParams +=
      '&tags[]=' +
      (params.autocorrect === 'yes'
        ? 'automarked'
        : params.autocorrect === 'partly'
          ? 'partially-automarked'
          : 'not-automarked')
  }
  if (params.calculator) {
    queryParams +=
      '&tags[]=' +
      (params.calculator === 'yes'
        ? 'calculator'
        : params.calculator === 'partly'
          ? 'partially-calculator'
          : 'no-calculator')
  }
  if (params.sources) {
    params.sources.forEach((source) => {
      queryParams += `&sources[]=${encodeURIComponent(source)}`
    })
  }
  if (params.query) {
    queryParams += `&query=${params.query}`
  }
  if (params.subChapters) {
    queryParams += params.subChapters
      .map((sc) => `&subChapters[]=${sc.id}`)
      .join('&')
  }

  // if (params.abilities) {
  //   Object.values(params.abilities).forEach((a, i) => {
  //     queryParams += `&abilities[${i}][ability]=${a}`
  //   })
  // }

  if (typeof params.pageSize === 'number' && params.pageSize > 0) {
    queryParams += `&pageSize=${params.pageSize}`
  }

  try {
    return (await http.get(`/v1/exams/search/?${queryParams}`)).data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to search exams')
  }
}

export async function examsCheckQuestions(courseId, questions) {
  try {
    const result = await http.post('/v1/exams/check-questions', {
      courseId,
      questions,
    })
    return result.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to check questions')
  }
}
