import utils from '../utils'

const DECIMALS = 2
// TODO: move to utils if needed elsewhere
const toFixed = (a, b) => (decimals) => parseFloat(a / b).toFixed(decimals)

const fonts = {
  h1: {
    fontSize: [utils.px2rem(36), utils.px2rem(48), utils.px2rem(48)], // TODO: test with only 2 values
    fontWeight: 300,
    lineHeight: [
      toFixed(45, 36)(DECIMALS),
      toFixed(60, 48)(DECIMALS),
      toFixed(60, 48)(DECIMALS),
    ],
    letterSpacing: [utils.px2rem(0.25), 0, 0],
  },
  h2: {
    fontSize: [utils.px2rem(28), utils.px2rem(36), utils.px2rem(36)],
    fontWeight: [600, 500, 500],
    lineHeight: [
      toFixed(35, 28)(DECIMALS),
      toFixed(45, 36)(DECIMALS),
      toFixed(45, 36)(DECIMALS),
    ],
    letterSpacing: utils.px2rem(0.25),
  },
  h3: {
    fontSize: [utils.px2rem(24), utils.px2rem(28), utils.px2rem(28)],
    fontWeight: 400,
    lineHeight: [
      toFixed(32, 24)(DECIMALS),
      toFixed(35, 28)(DECIMALS),
      toFixed(35, 26)(DECIMALS),
    ],
    letterSpacing: utils.px2rem(0.15),
  },
  h4: {
    fontSize: utils.px2rem(21),
    fontWeight: 600,
    lineHeight: toFixed(28, 21)(DECIMALS),
    letterSpacing: utils.px2rem(0.15),
  },
  h5: {
    fontSize: utils.px2rem(17),
    fontWeight: 600,
    lineHeight: toFixed(23, 17)(DECIMALS),
    letterSpacing: utils.px2rem(0.15),
  },
  h6: {
    fontSize: utils.px2rem(14),
    fontWeight: 700,
    lineHeight: toFixed(19, 14)(DECIMALS),
    letterSpacing: utils.px2rem(0.15),
  },
  large: {
    fontSize: utils.px2rem(18),
    fontWeight: 400,
    lineHeight: toFixed(28, 18)(DECIMALS),
    letterSpacing: utils.px2rem(0.1),
  },
  normal: {
    fontSize: utils.px2rem(16),
    fontWeight: 400,
    lineHeight: toFixed(22, 16)(DECIMALS),
    letterSpacing: utils.px2rem(0.1),
  },
  small: {
    fontSize: utils.px2rem(14),
    fontWeight: 400,
    lineHeight: toFixed(20, 14)(DECIMALS),
    letterSpacing: utils.px2rem(0.4),
  },
  xsmall: {
    fontSize: utils.px2rem(12),
    fontWeight: 400,
    lineHeight: toFixed(17, 12)(DECIMALS),
    letterSpacing: utils.px2rem(0.4),
  },
}

export default fonts
