import axios from 'axios'
import { useKMTokenStore } from '@/km/stores/token'
import { UserFacingError } from '@/utils/UserFacingError'

axios.defaults.params = {}
const http = axios.create({
  headers: {
    Accept: 'application/json, text/javascript, */*',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  baseURL: import.meta.env.VITE_KM_URL,
})

http.interceptors.request.use(
  (config) => {
    const token = useKMTokenStore()
    if (token.hasToken && !config.url.includes('/js/books/')) {
      config.headers.Authorization = 'Bearer ' + token.token
    }
    return config
  },
  (error) => {
    return Promise.reject(
      new UserFacingError(
        error,
        'Network error',
        false,
        'generic.error.network'
      )
    )
  }
)

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (typeof error.response === 'undefined') {
      return Promise.reject(
        new UserFacingError(
          error,
          'Network error',
          false,
          'generic.error.network'
        )
      )
    }
    if (error.response.status === 401 || error.response.status === 403) {
      return Promise.reject(
        new UserFacingError(
          error,
          'Session expired',
          false,
          'generic.error.auth'
        )
      )
    }
    return Promise.reject(error)
  }
)

export function throwOnAxiosError(error, message = 'Something went wrong') {
  if (error instanceof UserFacingError) {
    throw error
  }
  throw new UserFacingError(error, message)
}

export default http
